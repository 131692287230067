<template>
  <v-row justify-md="center" align="stretch">
    <v-col md="3">
      <v-card height="100%">
        <v-card-title class="text-h6">Hoe wil je je bestelling plaatsen?</v-card-title>
        <v-card-text>
          <v-radio-group v-model="action">
            <VRadio label="Ik heb al een account" value="login" color="primary"/>
            <VRadio label="Ik wil graag een account" value="register" color="primary"/>
            <VRadio label="Ik wil bestellen zonder account" value="nothing" color="primary"/>
          </v-radio-group>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col md="3">
      <LoginCard v-if="action === 'login'"/>
      <v-card v-else height="100%">
        <v-card-title class="text-h6" v-if="action === 'register'">Een account aanmaken</v-card-title>
        <v-card-title class="text-h6" v-else>Doorgaan zonder account</v-card-title>
        <v-card-text>
          <VTextField
              :rules="[(v) => !!v || 'E-mail is verplicht']"
              label="E-mail"
              v-model="email"
              tabindex="1"
          />
        </v-card-text>
        <v-card-actions>
          <VSpacer/>
          <v-btn color="primary" @click="continueToOrder()">Verder</v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import LoginCard from '../components/Authorisation/LoginCard.vue';
import { mapGetters, mapMutations } from 'vuex';

export default {
  name: 'OrderAccount',
  components: { LoginCard },
  data() {
    return {
      action: 'login',
    };
  },
  computed: {
    ...mapGetters({
      isLoggedIn: 'authorisation/isLoggedIn',
    }),
    email: {
      get() {
        return this.$store.state.order.email;
      },
      set(value) {
        this.setEmail(value);
      },
    },
  },
  created() {
    if (this.isLoggedIn) {
      this.$router.push({ name: 'make-order' });
    }
  },
  methods: {
    ...mapMutations({
      setEmail: 'order/setEmail',
      setCreateUser: 'order/setCreateUser',
    }),
    continueToOrder() {
      this.setCreateUser(this.action === 'register');
      this.$router.push({ name: 'make-order' });
    },
  },
};
</script>

<style scoped>

</style>
